import { Link } from "react-router-dom";
import { useState } from "react";

import classes from "./MainNavigation.module.css";
import Modal from "../ui/Modal";
import Backdrop from "../ui/Backdrop";
import logo from "../../assets/LogoLargo.png";

function MainNavigation() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModalHandler() {
    setModalIsOpen(true);
  }

  function closeModalHandler() {
    setModalIsOpen(false);
  }

  return (
    <header className={classes.header}>
      <div className={classes.imgContainer}>
        <img
          className={classes.logo}
          src={logo}
          alt="Logo Regina Varetto"
        ></img>
      </div>
      <nav className={classes.navBar}>
        <ul className={classes.navUl}>
          <li>
            <Link to="/">Sobre mí</Link>
          </li>
          <li>|</li>
          <li>
            <Link to="/servicios">Servicios</Link>
          </li>
          <li>|</li>
          <li>
            <Link onClick={openModalHandler}>Contacto</Link>
          </li>
          {/* <li>
            <Link to={pdf} target="_blank" download="Estrategias, habilidades, procedimientos y metacognición.pdf">Download</Link>
          </li> */}
        </ul>
      </nav>
      <div>
        {modalIsOpen ? (
          <Modal onCancel={closeModalHandler} onConfirm={closeModalHandler} />
        ) : null}
        {modalIsOpen && <Backdrop onClick={closeModalHandler} />}
      </div>
    </header>
  );
}

export default MainNavigation;
