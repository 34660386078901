import { useState } from "react";
import classes from "./Carousel.module.css";
import Backdrop from "./Backdrop";

function Carousel(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [zoomImageIsOpen, setBookImages] = useState(false);

  function goToNext() {
    const isLastSlide = currentIndex === props.slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }

  function goToPrevious() {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? props.slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  }

  function goToSlide(slideIndex) {
    setCurrentIndex(slideIndex);
  }

  function openZoomImage() {
    setBookImages(true);
  }
  function closeZoomImage() {
    setBookImages(false);
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.carouselContainer}>
        <div className={classes.leftArrow} onClick={goToPrevious}>
          ►
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src={props.slides[currentIndex]}
            alt=""
            onClick={openZoomImage}
          />
        </div>

        <div className={classes.rightArrow} onClick={goToNext}>
          ►
        </div>
      </div>
      <div className={classes.dotContainer}>
        {props.slides.map((slide, slideIndex) => {
          return (
            <div
              key={slideIndex}
              className={classes.dot}
              onClick={() => goToSlide(slideIndex)}
            >
              {currentIndex === slideIndex ? "◉" : "◯"}
            </div>
          );
        })}
      </div>
      <div>
        {zoomImageIsOpen ? (
          <div className={classes.zoomMainContainer}>
            <div className={classes.leftArrowZoom} onClick={goToPrevious}>
              ►
            </div>
            <div className={classes.zoomContainer}>
              <img
                className={classes.zoomImage}
                src={props.slides[currentIndex]}
                alt=""
                onClick={closeZoomImage}
              />
              <div className={classes.rightArrowZoom} onClick={goToNext}>
                ►
              </div>
            </div>
          </div>
        ) : null}
        {zoomImageIsOpen && <Backdrop onClick={closeZoomImage} />}
      </div>
    </div>
  );
}

export default Carousel;
