function Book(props) {
  return (
    <img
      className={props.className}
      src={props.book}
      alt=""
      onClick={props.action}
    />
  );
}

export default Book;
