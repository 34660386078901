import classes from "./ModalBook.module.css";
import CardBrown from "./Card_brown";
import Carousel from "./Carousel";
import { Link } from "react-router-dom";

function ModalBook(props) {
  function cancelHandler() {
    props.onCancel();
  }
  if (props.lola) {
    return (
      <div className={classes.modalNoGrid}>
        <div className={classes.buttonBackground}></div>
        <div className={classes.close} onClick={cancelHandler}></div>
        <div className={classes.curriculum}>
          <p className={classes.title}>PROFESORA DE LENGUAJE DE ENSEÑANZA MEDIA</p>
          <p>
            Especialista en el desarrollo de habilidades y competencias
            cognitivas y metacognitivas en la Comprensión Lectora.
          </p>
          <div>
            <p>Mi experiencia:</p>
            <ul>
              <li>
                19 años en colegio Sagrado Corazón de Apoquindo, Monjas Inglesas
                como profesora de aula.
              </li>
              <li>
                7 años como Coordinadora Departamento de Lenguaje Enseñanza
                Media, Colegio Andrée English School
              </li>
              <li>
                Capacitadora docente en cursos e-learning y
                presenciales Universidad de Chile.
              </li>
              <li>
                Publicaciones diversas en el área de la comprensión lectora,
                PSU-L y vocabulario contextual.
              </li>
            </ul>
          </div>
          <p>
            Mi especialidad es preparar alumnos-profesores que se inician en la
            docencia y capacitar profesores.
          </p>
          <p>
            He sido relatora pedagógica en colegios San Esteban y San Nicolás
            Diácono, Padre Hurtado y Juanita de Los Andes, Inmaculada Concepción
            de Puerto Varas, Duoc, Belén Educa, Colegios del Arzobispado de
            Santiago, Municipalidad de las Condes, Liceo San Felipe Benicio de
            Coyhaique , Liceo Comercial de San Bernardo, Colegio Santo Domingo
            de La Reina, Colegio Bicentenario de Talagante, Colegio Santa Cruz
            de Chicureo, Colegio Inmaculada Concepción de Puerto Varas, Colegio
            Santo Domingo de La Reina, Colegio SSCC de Concepción, Colegio San
            Francisco Javier de Puerto Montt, Colegio Inglés de Talca, Colegio
            Almondale, Concepción, Red Magister, Antofagasta, Secreduc Arica,
            ETP de Copiapó, CEAT de Concepción, Programa PACE Universidad de
            Atacama, Programa PACE Universidad de Los Lagos, Colegio Alianza
            Francesa de Santiago, Colegio Mariano de Providencia, Sip, Colegio
            San Francisco Javier de Huechuraba y Jornadas de actualización para
            profesores Universidad de Chile, entre muchos más y otros que
            vendrán.
          </p>
        </div>
      </div>
    );
  } else {
    if (props.downloadable) {
      return (
        <div className={classes.modal}>
          <div className={classes.buttonBackground}></div>
          <div className={classes.close} onClick={cancelHandler}></div>

          <div className={classes.carouselContainer}>
            <Carousel slides={props.slides}></Carousel>
          </div>
          <CardBrown>
            <div className={classes.description}>
              {props.description}
              <Link
                className={classes.link}
                to={props.downloadable.asset}
                target="_blank"
                download={props.downloadable.name}
              >
                Descarga el programa
              </Link>
            </div>
          </CardBrown>
        </div>
      );
    } else {
      return (
        <div className={classes.modal}>
          <div className={classes.buttonBackground}></div>
          <div className={classes.close} onClick={cancelHandler}></div>

          <div className={classes.carouselContainer}>
            <Carousel slides={props.slides}></Carousel>
          </div>
          <CardBrown>
            <div className={classes.description}>{props.description}</div>
          </CardBrown>
        </div>
      );
    }
  }
}

export default ModalBook;
