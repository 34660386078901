import classes from "./SocialMedia.module.css";
import facebook from "../../assets/facebook_logo.png";
import instagram from "../../assets/instagram_logo.png";
import linkedin from "../../assets/linkedin_logo.png";

function SocialMedia() {
  return (
    <div className={classes.container}>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://web.facebook.com/regina.varettocabrepreuniversitario"
      >
        <img className={classes.logo} src={facebook} alt="" />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.instagram.com/reginavarettocapacitaciones/"
      >
        <img className={classes.logo} src={instagram} alt="" />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.linkedin.com/in/regina-varetto-5a5025132/"
      >
        <img className={classes.logo} src={linkedin} alt="" />
      </a>
    </div>
  );
}

export default SocialMedia;
