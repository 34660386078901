import classes from "./InfoElipse.module.css";

function InfoElipse(props) {
  let position = props.position;
  return (
    <div className={`${classes.infoContainer} ${classes[position]}`}>
      <div className={classes.imageDiv}><img className={classes.image}  src={props.imagen} alt="" /></div>
      <p className={classes.title}>{props.title}</p>
      <p className={classes.description}>
        {props.description}
      </p>
    </div>
  );
}

export default InfoElipse;
