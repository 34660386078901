import { useState } from "react";
import { Helmet } from "react-helmet-async";

import Book from "../components/ui/Book";
import SocialMedia from "../components/ui/SocialMedia";
import ModalBook from "../components/ui/ModalBook";
import Backdrop from "../components/ui/Backdrop";

import pdf_estrategia from "../assets/pdf/Docs_capacitacionEstrategiasyHabilidades.pdf";
import pdf_enfoque from "../assets/pdf/Docs_enfoqueCritico.pdf";
import pdf_paes from "../assets/pdf/Docs_PAES.pdf";

import {
  arrayEstrategias,
  arrayEnfoque,
  arrayPaes,
  arrayLibros,
  bookDescriptions,
  arrayCompetencias,
  arrayLibEstrategias,
  arrayLosTrapenses,
  arrayManualVoc,
  arrayMiniEnsayos,
} from "../components/imports/BookArrays";

import classes from "./Services.module.css";

function Services() {
  const [modalBookIsOpen, setModalBookIsOpen] = useState(false);
  const [bookImages, setBookImages] = useState([]);
  const [bookDescription, setBookDescription] = useState("");
  const [downloadable, setDownloadable] = useState("");
  const [lola, setLola] = useState("");

  function openModalBookHandler(books, description, downloadable, lola) {
    setModalBookIsOpen(true);

    if (lola) {
      setLola(lola);
    } else {
      setLola();
      setBookDescription(description);
      setBookImages(books);
      if (downloadable) {
        setDownloadable({ asset: downloadable.asset, name: downloadable.name });
      } else {
        setDownloadable();
      }
    }
  }

  function closeModalBookHandler() {
    setModalBookIsOpen(false);
  }

  return (
    <>
      <Helmet>
        <title>Servicios Regina Varetto</title>
        <meta
          name="description"
          content="Busca en mi biblioteca lo que tengo disponible, información de capacitaciones, preuniversitario y mis libros"
        />
        <link rel="canonical" href="/servicios" />
      </Helmet>
      <div className={classes.pageLayout}>
        <div className={classes.mainContainer}>
          <div className={classes.shelf}>
            <div className={classes.firstShelf}>
              <Book
                book={arrayLibros[0]}
                className={classes.book1}
                action={() =>
                  openModalBookHandler("", "", "", bookDescriptions.quienSoy)
                }
              />
              <Book
                book={arrayLibros[1]}
                className={classes.book2}
                action={() =>
                  openModalBookHandler(
                    arrayLosTrapenses,
                    bookDescriptions.preuniversitario
                  )
                }
              />
              <div className={classes.shelfDescription}>Preuniversitario</div>
            </div>
            <div className={classes.secondShelf}>
              <Book
                book={arrayLibros[3]}
                className={classes.book4}
                action={() =>
                  openModalBookHandler(
                    arrayEstrategias,
                    bookDescriptions.estrategias,
                    {
                      asset: pdf_estrategia,
                      name: "Programa - Estrategias, habilidades, procedimientos y metacognición.pdf",
                    }
                  )
                }
              />
              <Book
                book={arrayLibros[4]}
                className={classes.book5}
                action={() =>
                  openModalBookHandler(arrayEnfoque, bookDescriptions.enfoque, {
                    asset: pdf_enfoque,
                    name: "Programa - Enfoque critico.pdf",
                  })
                }
              />
              <Book
                book={arrayLibros[5]}
                className={classes.book6}
                action={() =>
                  openModalBookHandler(arrayPaes, bookDescriptions.paes, {
                    asset: pdf_paes,
                    name: "Programa - PAES.pdf",
                  })
                }
              />
              <div className={classes.shelfDescription}>Capacitaciones</div>
            </div>
            <div className={classes.auxShelf}>
              <div className={classes.thirdShelf}>
                <Book book={arrayLibros[6]} className={classes.book7} />
                <Book
                  book={arrayLibros[7]}
                  className={classes.book8}
                  action={() =>
                    openModalBookHandler(
                      arrayManualVoc,
                      bookDescriptions.libroManual
                    )
                  }
                />
                <Book
                  book={arrayLibros[8]}
                  className={classes.book9}
                  action={() =>
                    openModalBookHandler(
                      arrayMiniEnsayos,
                      bookDescriptions.libroMiniEnsayos
                    )
                  }
                />
                <Book book={arrayLibros[9]} className={classes.book10} />
              </div>
              <div className={classes.fourthShelf}>
                <Book book={arrayLibros[10]} className={classes.book11} />
                <Book
                  book={arrayLibros[11]}
                  className={classes.book12}
                  action={() =>
                    openModalBookHandler(
                      arrayCompetencias,
                      bookDescriptions.libroCompetencias
                    )
                  }
                />
                <Book
                  book={arrayLibros[12]}
                  className={classes.book13}
                  action={() =>
                    openModalBookHandler(
                      arrayLibEstrategias,
                      bookDescriptions.libroEstrategias
                    )
                  }
                />
              </div>
              <div className={classes.bottomDescription}>Textos Escolares</div>
            </div>
          </div>
        </div>
        <SocialMedia />
        <div className={classes.modalContainer}>
          {modalBookIsOpen ? (
            <ModalBook
              onCancel={closeModalBookHandler}
              onConfirm={closeModalBookHandler}
              slides={bookImages}
              description={bookDescription}
              downloadable={downloadable}
              lola={lola}
            />
          ) : null}
          {modalBookIsOpen && <Backdrop onClick={closeModalBookHandler} />}
        </div>
      </div>
    </>
  );
}

export default Services;
