// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  collection,
  addDoc} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCLy5qvKmeTla7P2wmqXu6K9TuIiJlB_c4",
  authDomain: "reginavaretto-a9d46.firebaseapp.com",
  projectId: "reginavaretto-a9d46",
  storageBucket: "reginavaretto-a9d46.appspot.com",
  messagingSenderId: "504960478861",
  appId: "1:504960478861:web:fcf51ed7a597b156a7fcc4",
  measurementId: "G-PKYSYWVPMP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebase = getFirestore(app);

async function sendEmail(data){
  const mailCollection = collection(firebase, "mail");
  await addDoc(mailCollection, data);
}

export default sendEmail;