import classes from "./BookArrays.module.css";

//Galería Preuniversitario
import preu1 from "../../assets/preuniversitarios/preu/preu1.JPEG";
import preu2 from "../../assets/preuniversitarios/preu/preu2.jpg";
import preu3 from "../../assets/preuniversitarios/preu/preu3.JPG";
import preu4 from "../../assets/preuniversitarios/preu/preu4.JPEG";
import preu5 from "../../assets/preuniversitarios/preu/preu5.JPEG";
import preu6 from "../../assets/preuniversitarios/preu/preu6.JPEG";
import preu7 from "../../assets/preuniversitarios/preu/preu7.JPEG";
import preu8 from "../../assets/preuniversitarios/preu/preu8.JPEG";

//Galería Capacitación Enfoque crítico
import enf1 from "../../assets/capacitaciones/enfoque/enf1_small.jpg";

//Galería Capacitación Paes
import paes1 from "../../assets/capacitaciones/paes/paes1_small.jpg";

//Galería Capacitación Estrategias, Habilidades, Procedimientos y Metacognición
import est1 from "../../assets/capacitaciones/estrategias/est1_small.jpg";
import est2 from "../../assets/capacitaciones/estrategias/est2.JPG";
import est3 from "../../assets/capacitaciones/estrategias/est3.JPG";
import est4 from "../../assets/capacitaciones/estrategias/est4.JPG";
import est5 from "../../assets/capacitaciones/estrategias/est5.JPG";

//Galería libro Manual de vocabulario
import libManual0 from "../../assets/libros/vocabulario0.jpeg";
import libManual1 from "../../assets/libros/vocabulario1.jpg";

//Galería libro Mini Ensayos
import libPaes0 from "../../assets/libros/paes0.jpg";
import libPaes1 from "../../assets/libros/paes1.jpg";
import libPaes2 from "../../assets/libros/paes2.jpg";
import libPaes3 from "../../assets/libros/paes3.jpg";
import libPaes4 from "../../assets/libros/paes4.jpg";

//Galería libro Competencias lectoras
import libEnfoque0 from "../../assets/libros/enfoque0.jpg";
import libEnfoque1 from "../../assets/libros/enfoque1.jpg";
import libEnfoque2 from "../../assets/libros/enfoque2.jpg";
import libEnfoque3 from "../../assets/libros/enfoque3.jpg";
import libEnfoque4 from "../../assets/libros/enfoque4.jpg";
import libEnfoque5 from "../../assets/libros/enfoque5.jpg";

//Galería libro Estrategia
import libEstrategia0 from "../../assets/libros/estrategia0.jpg";
import libEstrategia1 from "../../assets/libros/estrategia1.jpg";
import libEstrategia2 from "../../assets/libros/estrategia2.jpg";
import libEstrategia3 from "../../assets/libros/estrategia3.jpg";
import libEstrategia4 from "../../assets/libros/estrategia4.jpg";

//Biblioteca
import quien_soy from "../../assets/web-Regina_libro_01.png";
import sede_trapenses from "../../assets/web-Regina_libro_02.png";
import sede_dominicos from "../../assets/web-Regina_libro_03.png";
import cap_estrategia from "../../assets/web-Regina_libro_04.png";
import cap_enfoque from "../../assets/web-Regina_libro_05.png";
import cap_paes from "../../assets/web-Regina_libro_06.png";
import lib_relleno1 from "../../assets/web-Regina_libro_07.png";
import lib_manual from "../../assets/web-Regina_libro_08.png";
import lib_mini from "../../assets/web-Regina_libro_09.png";
import lib_relleno2 from "../../assets/web-Regina_libro_010.png";
import lib_relleno3 from "../../assets/web-Regina_libro_011.png";
import lib_competencias from "../../assets/web-Regina_libro_012.png";
import lib_estrategia from "../../assets/web-Regina_libro_013.png";

const arrayLibros = [
  quien_soy,
  sede_trapenses,
  sede_dominicos,
  cap_estrategia,
  cap_enfoque,
  cap_paes,
  lib_relleno1,
  lib_manual,
  lib_mini,
  lib_relleno2,
  lib_relleno3,
  lib_competencias,
  lib_estrategia,
];
const arrayEstrategias = [est1, est2, est3, est4, est5];
const arrayEnfoque = [enf1];
const arrayPaes = [paes1];
const arrayQuienSoy = [];
const arrayLosTrapenses = [
  preu1,
  preu2,
  preu3,
  preu4,
  preu5,
  preu6,
  preu7,
  preu8,
];
const arrayLosDominicos = [];
const arrayManualVoc = [libManual0, libManual1];
const arrayMiniEnsayos = [libPaes0, libPaes1, libPaes2, libPaes3, libPaes4];
const arrayCompetencias = [
  libEnfoque0,
  libEnfoque1,
  libEnfoque2,
  libEnfoque3,
  libEnfoque4,
  libEnfoque5,
];
const arrayLibEstrategias = [
  libEstrategia0,
  libEstrategia1,
  libEstrategia2,
  libEstrategia3,
  libEstrategia4,
];
const bookDescriptions = {
  estrategias: estrategias(),
  enfoque: enfoque(),
  paes: paes(),
  preuniversitario: preuniversitario(),
  libroManual: libroManualVocabulario(),
  libroMiniEnsayos: libroMiniEnsayos(),
  libroCompetencias: libroCompetenciasLectoras(),
  libroEstrategias: libroEstrategias(),
  quienSoy: quienSoy(),
};

function estrategias() {
  return (
    <p>
      A partir de mi experiencia en aula, el curso-taller aborda, desde un
      enfoque cognitivo, el tema de la comprensión lectora y su tratamiento en
      las aulas chilenas, desde los requerimientos actuales del Mineduc.
      Posibilita a los docentes replicar en su práctica pedagógica un modelo
      explícito o de instrucción directa de estrategias y competencias lectoras.
      Se enfoca en el desarrollo de habilidades, procedimientos y modelamiento
      de estrategias desde lo cognitivo y metacognitivo.
    </p>
  );
}

function enfoque() {
  return (
    <p>
      Comprender críticamente un texto requiere reconstruir tanto el contenido
      como la ideología o creencias del emisor. Comprender críticamente un texto
      implica detectar subjetividades que trasluce el lenguaje, a partir de:
      recursos discursivos, posicionamiento del enunciador, el propósito
      comunicativo, la perspectiva discursiva, las voces incorporadas, el género
      discursivo, etc. Proponer un modelo práctico de trabajo, basado en los
      conceptos, componentes y mecanismos de funcionamiento del enfoque crítico
      de la comprensión de textos, para entregar herramientas concretas a los
      docentes y que puedan, luego, ser transferidas a los/as alumnos/as. La
      enseñanza en lectura crítica permite a los estudiantes profundizar en la
      interpretación de las opiniones (actitudes, puntos de vista, concepciones,
      intenciones, visiones de mundo, etc.) de un escrito y su autor, y no solo
      en la comprensión de su contenido (ideas principales, inferencias, etc.).
    </p>
  );
}

function paes() {
  return (
    <div>
      <p>
        Proponer un modelo práctico de trabajo en el ámbito de la comprensión de
        textos, basado en procedimientos, estrategias, habilidades y
        metacognición para encauzar el trabajo de los docentes en el actual
        enfoque de la PAES de Lenguaje. Las preguntas se enfocan en evaluar las
        habilidades que promueve el currículo escolar de Lenguaje y Comunicación
        en miras a la educación superior. Así, los textos y preguntas apuntan a
        una experiencia de lectura cercana y semejante a la que se lleva a cabo
        en situaciones reales (personales, sociales y educativas).
      </p>
      <p>
        A partir de mi experiencia en aula, el curso-taller aborda, desde un
        enfoque cognitivo, el tema de la comprensión lectora en la actual PAES
        de Lenguaje. Posibilita a los docentes replicar en su práctica
        pedagógica un modelo de competencias y habilidades, a través de
        estrategias y procedimientos explícitos desde lo cognitivo y
        metacognitivo.
      </p>
    </div>
  );
}

function preuniversitario() {
  return (
    <div>
      <p>
        REGINA VARETTO PREUNIVERSITARIO cuenta con la experiencia de más de 30
        años preparando alumnos y alumnas para rendir la prueba de lenguaje de
        ingreso a la Universidad. Ha logrado dos máximos nacionales en la
        anterior PSU y un excelente resultado que avalan su permanencia en el
        mercado.
      </p>
      <p>
        Las clases son grupales (máximo 8 estudiantes) y se trabaja con los
        manuales publicados por Regina.
      </p>
      <p>
        Los estudiantes pueden elegir el horario que mejor les convenga pues
        existen diferentes bloques y días.
      </p>
      <p>A partir del año 2023, contamos con tres sedes:</p>
      <ul>
        <li>Los Domínicos: calle Visviri 1550. Las Condes.</li>
        <li>WeWork Apoquindo, Apoquindo 5960, Las Condes.</li>
        <li>
          ConetWork Mall Vivo Los Trapenses, Avda. José Alcalde Délano 10545,
          of. 301, Lo Barnechea.
        </li>
      </ul>
    </div>
  );
}

function libroManualVocabulario() {
  return (
    <div>
      <p>
        El siguiente Manual de Vocabulario Contextual pretende ser un real
        aporte para las clases de Lenguaje. Quisiéramos manifestar, que si bien,
        la motivación primera para la creación de este material, fue la
        preparación del ítem de vocabulario para la PSU de Lenguaje, con el
        transcurrir de su elaboración llegamos a la convicción de que para
        pensar y expresar acertadamente las ideas, se requiere de un bagaje
        cultural mediatizado por las palabras.
      </p>
      <p>
        De ahí que surge que nuestros alumnos tomen conciencia de la real
        trascendencia de manejar un vocabulario amplio y rico en matices y
        sutilezas. Ya no es una necesidad restringida al óptimo rendimiento de
        una prueba de selección universitaria, sino que es una necesidad para la
        vida. Tal como señalara el filósofo austríaco Ludwig Josef Johann
        Wittgenstein, “
        <strong>los límites de mi lenguaje son los límites de mi mundo</strong>
        ”.
      </p>
      <p>
        Hemos tratado de contextualizar todas las palabras, en ejercicios que
        requieran más que pura memorización. Hemos incorporado el ámbito
        connotativo o metafórico de las palabras, aspecto que muchas veces no
        aparece en los diccionarios y que se obtiene de las interrelaciones
        socioculturales entre los miembros de una comunidad. Diversas
        estrategias para la adquisición de léxico, se han ido incorporando. Cada
        lección viene precedida de objetivos-contenidos-orientaciones
        metodológicas-, para terminar con una evaluación. Esperamos con este
        material, apoyar tanto a profesores como a estudiantes en su quehacer
        académico.
      </p>
      <div>
        <a
          rel="noopener noreferrer"
          className={classes.link}
          target="_blank"
          href="https://www.editorialcid.com/#nuestros-libros"
        >
          ¡Compra el libro aquí!
        </a>
      </div>
    </div>
  );
}
function libroMiniEnsayos() {
  return (
    <div>
      <p>
        La comprensión lectora, actualmente, se configura como el eje central
        del sistema educativo chileno, para desarrollar y fortalecer habilidades
        de pensamiento. Este texto contiene 10 Mini Ensayos con 35 preguntas
        cada uno, cada uno de ellos contempla una habilidad o estrategia en
        particular, como una forma de ejercitar y profundizar la comprensión
        lectora que mide la actual PAES de Lenguaje
      </p>
      <div>
        <a
          rel="noopener noreferrer"
          className={classes.link}
          target="_blank"
          href="https://www.editorialcid.com/#nuestros-libros"
        >
          ¡Compra el libro aquí!
        </a>
      </div>
    </div>
  );
}
function libroCompetenciasLectoras() {
  return (
    <div>
      <p>
        Desarrolla el pensamiento crítico en la comprensión de textos. El texto
        pretende acercarte a un análisis crítico del discurso a partir de una
        serie de elementos como la identificación de la ideología, el
        posicionamiento del emisor, los recursos discursivos, la controversia y
        el análisis de perspectivas
      </p>
      <div>
        <a
          rel="noopener noreferrer"
          className={classes.link}
          target="_blank"
          href="https://www.editorialcid.com/#nuestros-libros"
        >
          ¡Compra el libro aquí!
        </a>
      </div>
    </div>
  );
}
function libroEstrategias() {
  return (
    <div>
      <p>
        Este texto enfatiza el desarrollo de procedimientos lectores y el
        modelamiento, por parte del profesor, de estrategias desde lo cognitivo
        y metacognitivo. A partir de enfoques actuales, esto es, desde una
        perspectiva de la competencia comunicativa: Leer para aprender;
        conjuntamente este desarrollo estará alineado con los últimos cambios
        realizados en la PAES-L en el ámbito de la comprensión de lectura. Entre
        los objetivos están:
      </p>
      <ul>
        <li>
          Conocer y desarrollar estrategias cognitivas y metacognitivas para el
          proceso de lectura.
        </li>
        <li>
          Comprender los conceptos de cognición y metacognición para entender la
          lógica de la comprensión lectora.
        </li>
        <li>
          Conocer y comprender la importancia de los procedimientos explícitos
          (saber hacer) en la comprensión de textos.
        </li>
        <li>Comprender el entrecruce entre estrategia y habilidad.</li>
      </ul>
      <div>
        <a
          rel="noopener noreferrer"
          className={classes.link}
          target="_blank"
          href="https://www.editorialcid.com/#nuestros-libros"
        >
          ¡Compra el libro aquí!
        </a>
      </div>
    </div>
  );
}
function quienSoy() {
  return <div>Wena Shoro</div>;
}

export {
  arrayEstrategias,
  arrayEnfoque,
  arrayPaes,
  arrayLibros,
  bookDescriptions,
  arrayCompetencias,
  arrayLibEstrategias,
  arrayLosDominicos,
  arrayLosTrapenses,
  arrayManualVoc,
  arrayMiniEnsayos,
  arrayQuienSoy,
};
