import { useRef } from "react";
import classes from "./Modal.module.css";
import CardBrown from "./Card_brown";
import sendEmail from "../../firebase/initializeApp";

function Modal(props) {
  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const temaInputRef = useRef();
  const descriptionInputRef = useRef();

  function confirmHandler(event) {
    event.preventDefault();

    const htmlMail = `
    <p>${nameInputRef.current.value} te envió un mensaje utilizando el correo: ${emailInputRef.current.value}</p>
    <p>Su mensaje dice lo siguiente:</p>
    <p>${descriptionInputRef.current.value}</p>
    `

    const email = {
      to: ["regina.varetto@gmail.com"],
      message: {
        subject: temaInputRef.current.value,
        html: htmlMail,
        text: "Recuerda responder el correo en un mensaje aparte"
      }
    }
    sendEmail(email);
    props.onConfirm();
  }

  function cancelHandler() {
    props.onCancel();
  }

  return (
    <div className={classes.modal}>
      <div className={classes.buttonBackground}></div>
      <div className={classes.close} onClick={cancelHandler}></div>
      <div className={classes.title}>
        <p className={classes.text_title}>Contacto</p>
        <hr />
        <p>Si tienes dudas escríbeme</p>
      </div>
      <CardBrown>
        <form className={classes.form} onSubmit={confirmHandler}>
          <hr />
          <div className={classes.control}>
            <input
              type="text"
              required
              id="nombre"
              placeholder="Nombre:"
              ref={nameInputRef}
            />
          </div>
          <div className={classes.control}>
            <input
              type="email"
              required
              id="email"
              placeholder="Email:"
              ref={emailInputRef}
            />
          </div>
          <div className={classes.control}>
            <input
              type="text"
              required
              id="tema"
              placeholder="Tema:"
              ref={temaInputRef}
            />
          </div>
          <div className={classes.control}>
            <textarea
              id="description"
              required
              rows="5"
              placeholder="Tu mensaje:"
              ref={descriptionInputRef}
            ></textarea>
          </div>
          <div className={classes.actions}>
            <button>Enviar Mensaje</button>
          </div>
          <hr />
        </form>
      </CardBrown>
    </div>
  );
}

export default Modal;
