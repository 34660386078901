import InfoElipse from "../components/ui/InfoElipse";
import SocialMedia from "../components/ui/SocialMedia";
import classes from "./Home.module.css";
import foto_lola from "../assets/foto_lola_real.png";
import foto_lola2 from "../assets/ReginaFotoNuevaPequena.png";
import fondo_azul from "../assets/fondo_azul.png";
import semicirculo from "../assets/semicirculo.png";
import experiencia from "../assets/experiencia.png";
import profesionalismo from "../assets/profesionalismo.png";
import compartir from "../assets/compartir.png";
import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <><Helmet>
      <title>Regina Varetto</title>
      <meta
      name="description"
      content="Este es mi sitio web personal donde podrás encontrar toda la información referente a mis libros, capacitaciones, preuniversitario y mis redes sociales"
      />
      <link rel="canonical" href="/" />
    </Helmet><div className={classes.pageLayout}>
        <div className={classes.mainContainer}>
          <div className={classes.descriptionContainer}>
            <div className={classes.myName}>Mi nombre es</div>
            <div className={classes.name}>Regina Varetto Cabré</div>
            <p className={classes.paragraph}>
              Soy profesora de aula por más de 30 años, creadora de REGINA VARETTO
              PREUNIVERSITARIO. Autora de más de diez textos escolares
              relacionados con la comprensión lectora, la PAES y vocabulario.
              Recorro Chile capacitando docentes en el tema del desarrollo de
              habilidades y estrategias lectoras.
            </p>
          </div>
          <div className={classes.photo}>
            {/* <img
              className={classes.backgroundCard}
              src={fondo_azul}
              alt="Foto de Regina"
            ></img>
            <img
              className={classes.backgroundCircle}
              src={semicirculo}
              alt="Foto de Regina"
            ></img> */}
            <img
              className={classes.backgroundPhoto}
              src={foto_lola2}
              alt="Foto de Regina"
            ></img>
          </div>
        </div>
        <SocialMedia />
        <div className={classes.experiencia}>
          <InfoElipse
            imagen={experiencia}
            position="left"
            title="Experiencia"
            description="Más de 30 años enseñando y aprendiendo" />
        </div>
        <div className={classes.profesionalismo}>
          <InfoElipse
            imagen={profesionalismo}
            position="center"
            title="Profesionalismo"
            description="Dedicación y entrega completa en lo que hago" />
        </div>

        <div className={classes.compartir}>
          <InfoElipse
            imagen={compartir}
            position="right"
            title="Compartir"
            description="Quiero que estudiantes y docentes reciban lo que he aprendido" />
        </div>
      </div></>
  );
}

export default Home;
